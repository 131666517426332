import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "navigation"
    }}>{`Navigation`}</h1>
    <p>{`@moble Hamburger`}</p>
    <p>{`@tablet`}</p>
    <p>{`@desktop`}</p>
    <p>{`No external links in main nav`}</p>
    <p>{`Sub-nav`}</p>
    <p>{`Remove accordion structure/interface`}</p>
    <p>{`Have sub-nav indicate current location`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <p>{`Main Navigation`}</p>
    <p>{`Differentiation between headers with linked pages or plain headers in mega menu`}</p>
    <p>{`Differentiation of external links`}</p>
    <p>{`Leading/Line Height`}</p>
    <p>{`Top Bar`}</p>
    <p>{`Use of Icons/Consistency`}</p>
    <p>{`Use of multiple fonts`}</p>
    <p>{`Other Navigation`}</p>
    <p>{`Use of Headers and Links in Sidebar/Mobile Navigation`}</p>
    <p>{`Use of Accordions in Sidebar/Mobile Navigation`}</p>
    <p>{`Mobile Navigation`}</p>
    <p>{`Use of logo`}</p>
    <p>{`Color consistency`}</p>
    <p>{`Footer Navigation`}</p>
    <p>{`CONTACT – when to include, when to leave out?`}</p>
    <h2 {...{
      "id": "using-different-kinds"
    }}>{`Using different kinds`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      